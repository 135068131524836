import { Link } from "@StarberryUtils";
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PlayVideo from "../../Play/CustomVideo";
// import TeamMember1 from "../../../images/Team-details/team1.jpg";
// import TeamMember1Tablet from "../../../images/Team-details/team1_tablet.jpg";
// import TeamMember1Mobile from "../../../images/Team-details/team1_mobile.jpg";
// import TeamMember2 from "../../../images/Team-details/team2.jpg";
// import TeamMember2Tablet from "../../../images/Team-details/team2_tablet.jpg";
// import TeamMember3 from "../../../images/Team-details/team3.jpg";
// import TeamMember4 from "../../../images/Team-details/team4.jpg";
// import { isMobile, isMobileTab } from "../../jsutility/index";
// import { isTablet, isIPad13, isIOS13 } from 'react-device-detect';
import ModalTiny from "react-bootstrap/Modal"
import ReachUsForm from "../../Forms/react-us-form";
import _ from "lodash";
import "./TeamMember.scss";
import logoBlack from "../../../images/logoblack.svg";
import { useStaticQuery, graphql } from "gatsby"
import { inViewOptions, containerVariants, delayItemsFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const AllMembers = (props) => {
  const GET_ALL_MEMBERS = useStaticQuery(graphql`
    query {
         glstrapi {
              teams(sort:"Sort:asc", where:{Publish:true}) {
                id
                Name
                Tile_Image {
                  url
                  alternativeText
                }
                URL
                Designation
                Email
                Phone
                Departments {
                  Title
                  URL
                }
                offices {
                  Name
                  URL
                }
              }
          }
  }`);
  const data = GET_ALL_MEMBERS?.glstrapi;
  // const { loading, error, data } = useQuery(GET_ALL_MEMBERS);
  const [people, setPeople] = useState("");
  const [peopleNumber, setPeopleNumber] = useState("");
  const [modalContactformOpen, setContactformOpen] = React.useState(false);
  const closeContactformModal = () => {
    setContactformOpen(false);
  }

  const openContactformModal = (people, peopleNumber) => {
    setPeople(people)
    setPeopleNumber(peopleNumber)
    setContactformOpen(true);
  }

  // if (loading) return <div className="gql-loading-screen"><p>Loading ...</p></div>;
  // if (loading) return (
  //   <section className={"loader-wrapper"}>
  //     <div id="loader-wrapper">
  //       <div id="loader" class="new-loader">
  //         {/* <div className="new-loader-anime"></div> */}
  //         <img className="logo-white" src={logoBlack} className="loader-logo" alt="DB Roberts logo" />
  //       </div>
  //       <div class="loader-section section-left"></div>
  //       <div class="loader-section section-right"></div>
  //     </div>
  //   </section>
  // );

  var TeamList = data?.teams;
  var filteredTeam = TeamList ? TeamList : [];
 
  if (props.office_category !== "all_offices") {
    filteredTeam = TeamList.filter(team => { 
      let category = _.includes(_.map(team.offices, 'URL'), props.office_category)
    //   if(_.includes(["bill brookes", "jo culley", "matt hill", "matt hood"], _.toLower(team.Name))){ 
    //     category = true;
    //   }  
      if (category) {
        return category
      }
    });
    
  }
  if (props.category !== "all_departments") {
    if(props.category == "lettings"){
      filteredTeam = TeamList;
    }
    filteredTeam = filteredTeam.filter(team => {
      let category = (team.Departments?.map(c => c.URL).includes(props.category));
    //   if(_.includes(["bill brookes", "jo culley", "matt hill", "matt hood"], _.toLower(team.Name)) && props.category == "lettings"){ 
    //     category = true;
    //   }  
      if (category) {
        return category
      }
    });
  }

  const officeName = (item)=>{  
    if(_.toLower(item.Designation) === "branch manager" || _.toLower(item.Designation) === "area manager"){  
      return  _.join(_.map(item.offices.slice(0,2), 'Name'), ' & ');
    }  
  }

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="team-section"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <Row>
              <Col>
                <Row>
                  {filteredTeam?.map((item, index) => (
                    <Col lg={3} md={6} sm={6} className="team-tile" key={index}>
                      <motion.div
                        custom={index}
                        variants={delayItemsFromBottom}
                      >
                        <div key={index} className="team-member">
                          <Link to={item.URL} className="team-member-name">
                            <div className="team-member-img img-zoom">
                              <picture>
                                <source
                                  media="(min-width:992px)"
                                  srcSet={item.Tile_Image.url}
                                />
                                <source
                                  media="(min-width:768px)"
                                  srcSet={item.Tile_Image.url}
                                />
                                <img className="" src={item.Tile_Image.url} alt={`${item.Name} ${item.Designation} - DB Roberts`}  />
                              </picture>
                              {item.Video_URL &&
                                <div className="news-btn">
                                  <PlayVideo url={item.Video_URL} />
                                </div>
                              }
                            </div>
                          </Link>
                          <div className="team-member-info">
                            <Link to={item.URL} className="team-member-name">
                              <div className="d-flex align-items-center team-member-hover">
                                {item.Name}
                                <i className="icon-arrow ml-auto"></i>
                              </div>
                            </Link>

                            <span className="team-member-designation">
                              {officeName(item)} {item.Designation}
                            </span>
                            <a
                              href={`javascript:void(0)`}
                              className="team-member-email"
                              onClick={() => { openContactformModal(item.Name, item.Phone) }}
                            >
                              {item.Email}
                            </a>
                          </div>

                        </div>
                      </motion.div>
                    </Col>
                  ))}

                  {filteredTeam.length == 0 &&
                    <div className="no-people-found">
                      No People found
                    </div>
                  }
                </Row>
              </Col>
            </Row>
          </Container>
          <ModalTiny size="lg" show={modalContactformOpen} onHide={closeContactformModal} className="modal-form">
            <ModalTiny.Header closeButton className="contact-close-btn">
              <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Contact {people}</h2></ModalTiny.Title>
            </ModalTiny.Header>
            <ModalTiny.Body className="event-section"><ReachUsForm title={`Contact ${people}`} office={""} people_name={people} people_number={peopleNumber} email_temp_user="individual_contact_user" /></ModalTiny.Body>
          </ModalTiny>
        </motion.div>
      )}
    </InView>
  );
};

export default AllMembers;
